import { cva, type VariantProps } from 'class-variance-authority';
import { type ReactNode } from 'react';

const sidePanel = cva(
  'flex flex-col border-l justify-between h-[100%] divide-gray-200 divide-y overflow-hidden',
);

export type SidePanelProps = VariantProps<typeof sidePanel> & {
  children: ReactNode;
};

/**
 * Controlled component with optional CTAs at the bottom intended to complement primary content.
 * Use the adjacent SidePanelCTA component to render CTAs.
 *
 * @variation desktop - Content pushed to the left; remains visible while users interact with primary content
 * @variation mobile - Behaves like a drawer; users unable to interact with primary content
 *
 * @example
 * <SidePanel
 *   title="Some title"
 *   onClose={() => setOpen(false)}
 * >
 *   <SidePanelContent>{/* ... /*}</SidePanelContent>
 *   <SidePanelCTA>{/* ... /*}</SidePanelCTA>
 * </SidePanel>
 */
export const Root = ({ children }: SidePanelProps) => {
  return <div className={sidePanel()}>{children}</div>;
};
